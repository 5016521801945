<template>
	<div id="mobile-menu" class="mobile-menu d-flex flex-column flex-row-auto">
		<div class="mobile-menu-wrapper flex-column-fluid">
			<div class="mobile-menu-container mt-4 pb-4" data-menu-dropdown-timeout="500" data-menu-scroll="1" data-menu-vertical="1">
				<ul id="mobileAccordionFreefile" class="mobile-menu-nav accordion">
					<li class="mobile-menu-item accordion-item pt-2 pb-2">
						<a class="accordion-link" href="https://www.irs.gov/freefile" target="_blank">
							<span class="mobile-menu-title"><span class="menu-text">Return to Free File</span></span>
						</a>
					</li>
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseOne" aria-expanded="true" class="accordion-button collapsed" data-bs-target="#collapseOne" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">Support</span></span>
						</button>
						<div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#mobileAccordionFreefile">
							<div class="accordion-body">
								<router-link class="mobile-menu-link" to="/freefile2024/contact" @click="toggleMobileMenu">
									<span class="menu-text">Customer Support</span></router-link>
								<a :href="constants.contactSupportUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">My Support Issues</span></a>
							</div>
						</div>
					</li>
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseTwo" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#collapseTwo" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">After You File</span></span>
						</button>
						<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#mobileAccordionFreefile">
							<div class="accordion-body">
								<a :href="constants.checkUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">Check E-File Status</span></a>
								<router-link class="mobile-menu-link" to="/freefile2024/refund-status" @click="toggleMobileMenu">
									<span class="menu-text">Where's My Refund</span></router-link>
								<a :href="constants.printUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">Print / Download My Return</span></a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div id="mobile-menu-overlay" class="mobile-menu-overlay" @click="toggleMobileMenu"></div>
</template>

<script setup>
import {onMounted, watch} from "vue"
import menuToggle from "@/functions/menuHandlerMobile"
import {useRoute} from "vue-router"
import {constants} from "@/functions/constants-freefile"

const {closeMobileMenu, toggleMobileMenu} = menuToggle()
const route = useRoute()

onMounted(() => {
	window.addEventListener("DOMContentLoaded", closeMobileMenu)
	window.addEventListener("resize", closeMobileMenu)
	document.addEventListener("keydown", (evt) => {
		if (evt.key === "Escape") {
			closeMobileMenu()
		}
	})

	function dataGet() {
		let accordionCollapseItems = document.getElementById(
			"mobileAccordionFreefile"
		).children
		for (let i = 0; i < accordionCollapseItems.length; i++) {
			let menuButton = accordionCollapseItems[i].children[0]
			if (!menuButton.classList.value.includes("accordion-link")) {
				menuButton.classList.add("collapsed")
				let menuDiv = accordionCollapseItems[i].children[1]
				menuDiv.classList.remove("show")
			}
		}
	}

	watch(route, () => {
		dataGet()
	})
})
</script>
