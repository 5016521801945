const getPriorYears = async () => {
	const priorYears = [process.env.VUE_APP_TAX_YEAR -1]
	for (let i = 1; i < 7; i++) {
		priorYears.push(process.env.VUE_APP_TAX_YEAR - 1 - i)
	}

	return priorYears
}

export default getPriorYears
