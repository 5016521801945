<template>
	<div id="mobile_nav_hamburger_container" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="d-flex d-lg-none align-items-center">
		<span class="sr-only"> - Opens the menu</span>
		<div id="mobile_nav_hamburger" class="flex-fill" tabindex="0" @click="toggleMobileMenu">
			<span></span> <span></span> <span></span> <span></span>
		</div>
	</div>
</template>

<script setup>
import menuToggle from "@/functions/menuHandlerMobile"

const {toggleMobileMenu} = menuToggle()
</script>
